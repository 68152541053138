export default {
  name: 'NewExperience',

  methods: {
    itemDetail(type) {
      this.showModal = true;
      if (type === 'yoga') {
        this.info.img = this.img2;
        this.info.name = 'Teach Yoga Online';
        this.info.description = 'Utilize your yoga experience and teach globally.';
      } else if (type === 'music') {
        this.info.img = this.img3;
        this.info.name = 'Teach Music Online';
        this.info.description = 'Help others learn music and get paid.';
      } else if (type === 'meditation') {
        this.info.img = this.img4;
        this.info.name = 'Teach Meditation';
        this.info.description = 'If you can meditate, anyone can meditate.';
      } else if (type === 'cooking') {
        this.info.img = this.img5;
        this.info.name = 'Teach Cooking Online';
        this.info.description = 'Let the world learn from your cooking skills.';
      } else if (type === 'storytelling') {
        this.info.img = this.img6;
        this.info.name = 'Teach Storytelling';
        this.info.description = 'Share the art of storytelling with others.';
      } else if (type === 'online-tours') {
        this.info.img = this.img7;
        this.info.name = 'Host Online Tours';
        this.info.description = 'Utilize your past travel photos, videos to host online tours (e-tours).';
      } else if (type === 'magic') {
        this.info.img = this.img8;
        this.info.name = 'Teach Magic Online';
        this.info.description = 'Teach magic online and earn.';
      } else if (type === 'painting') {
        this.info.img = this.img9;
        this.info.name = 'Teach Painting Online';
        this.info.description = "Let's paint! Bring colors to others' life.";
      } else if (type === 'art-and-craft') {
        this.info.img = this.img11;
        this.info.name = 'Teach Art & Craft';
        this.info.description = 'Teach others with your experience in art & crafts.';
      } else if (type === 'languages') {
        this.info.img = this.img12;
        this.info.name = 'Teach Languages';
        this.info.description = 'If you know how to teach, join the community.';
      } else if (type === 'writing') {
        this.info.img = this.img13;
        this.info.name = 'Teach Writing Tips';
        this.info.description = 'If you can help others write, start it now.';
      } else if (type === 'speaking') {
        this.info.img = this.img14;
        this.info.name = 'Teach Public Speaking';
        this.info.description = 'If you a speaker, join us and help others.';
      } else if (type === 'fitness') {
        this.info.img = this.img15;
        this.info.name = 'Teach how to stay fit?';
        this.info.description = 'This community is for everyone. Join us.';
      } else if (type === 'indoor') {
        this.info.img = this.img16;
        this.info.name = 'Teach Indoor Sports';
        this.info.description = 'What are you good it? Teach others.';
      } else if (type === 'anything') {
        this.info.img = this.img10;
        this.info.name = 'Teach Anything Online';
        this.info.description = 'Teach whatever you are good at. Anything!';
      }
    }
  },

  data() {
    return {

      info: {
        img: this.img2,
        name: 'Yoga',
        description: 'Teach yoga online from your home and earn.'
      },

      img2: require("@/assets/imgs/ee/2.jpg"),
      img3: require("@/assets/imgs/ee/3.jpg"),
      img4: require("@/assets/imgs/ee/4.jpg"),
      img5: require("@/assets/imgs/ee/5.jpg"),
      img6: require("@/assets/imgs/ee/6.jpg"),
      img7: require("@/assets/imgs/ee/7.jpg"),
      img8: require("@/assets/imgs/ee/8.jpg"),
      img9: require("@/assets/imgs/ee/9.jpg"),
      img10: require("@/assets/imgs/ee/10.jpg"),

      img11: require("@/assets/imgs/ee/11.jpg"),
      img12: require("@/assets/imgs/ee/12.jpg"),
      img13: require("@/assets/imgs/ee/13.jpg"),

      img14: require("@/assets/imgs/ee/14.jpg"),
      img15: require("@/assets/imgs/ee/15.jpg"),
      img16: require("@/assets/imgs/ee/16.jpg"),

      lazy: require("@/assets/imgs/dark-lazy.png"),

      showModal: false
    }
  }
}